import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AngularFireAuth} from "@angular/fire/auth";
import {LoginComponent} from "../login/login.component";
import {RegisterComponent} from "../register/register.component";
import {DeleteUserComponent} from "../deleteUser/deleteuser.component";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import { saveAs } from 'file-saver';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private modalService: NgbModal, public afAuth: AngularFireAuth, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    if (this.router.url == '/login') {
      this.login();
    }
  }

  login(): void {
    this.modalService.open(LoginComponent).result.then(data => {});
  }

  register(): void {
    this.modalService.open(RegisterComponent).result.then(data => {
      if (data == "login") {
        this.login();
      }
    });
  }

  logout(): void {
    this.afAuth.auth.signOut().then(() => {
      console.log("signOut successful");
    }).catch(error => {
      console.log(error);
    });
  }

  deleteUser(): void {
    this.modalService.open(DeleteUserComponent).result.then(data => {
    });
  }

  download() {
  }


  saveFile() {
    this.http.get("", {responseType: 'blob'}).subscribe(value => {

      var bag: BlobPropertyBag = {type: "application/jpeg"};
      var blob = new Blob([value], bag);
      saveAs(blob, 'test.jpg');
    });
  }
}
