import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { faExpandAlt, faArrowLeft, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VerifyAccountComponent} from "../verifyAccount/verifyaccount.component";

@Component({
	selector: 'slideshow',
	templateUrl: './slideshow.component.html',
	styleUrls: ['./slideshow.component.css']
})
export class SlideshowComponent implements OnInit {

	faExpandAlt = faExpandAlt;
  faArrowLeft = faArrowLeft;
  faCloudDownloadAlt = faCloudDownloadAlt;

  elementsVisible = true;

	images: string[] = [""];
	image: string;

	positionString: string;

	timeout: number;

	constructor(private firestore: AngularFirestore, private afAuth: AngularFireAuth, private modalService: NgbModal) { }

	ngOnInit(): void {
    this.registerPhotos();
    this.mouseMoved();

		this.showSlide(0);

		if (!this.afAuth.auth.currentUser.emailVerified) {
      this.modalService.open(VerifyAccountComponent).result.then(data => {
      });
    }
	}

	registerPhotos(): void {
	  const uid = this.afAuth.auth.currentUser.uid;

		this.firestore.collection('photos', ref => ref.where('user', '==', uid)).valueChanges().subscribe((result: any) => {
			this.images = result.map(a => a.url);
		});
	}

	showSlide(slide: number): void {
		this.image = this.images[slide];
		const currentPage: number = slide + 1;
		this.positionString = currentPage.toString() + "/" + this.images.length.toString();
		setTimeout(() => {
			const next = slide + 1;

			if (this.images.length == 0) {
			  this.image = null;
      } else if (next >= this.images.length) {
				this.showSlide(0);
			} else {
				this.showSlide(next);
			}
		}, 5000);
	}

	toggleFullscreen(): void {
    const isInFullScreen = (document.fullscreenElement && true) || (document['webkitFullscreenElement'] && document['webkitFullscreenElement'] !== null) || (document['mozFullScreenElement'] && document['mozFullScreenElement'] !== null) ||
      (document['msFullscreenElement'] && document['msFullscreenElement'] !== null);

    const docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm['mozRequestFullScreen']) {
        docElm['mozRequestFullScreen']();
      } else if (docElm['webkitRequestFullScreen']) {
        docElm['webkitRequestFullScreen']();
      } else if (docElm['msRequestFullscreen']) {
        docElm['msRequestFullscreen']();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
      } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']();
      } else if (document['msExitFullscreen']) {
        document['msExitFullscreen']();
      }
    }
	}

  mouseMoved() {
	  clearTimeout(this.timeout);
    this.elementsVisible = true;

    this.timeout = setTimeout(() => {
      this.elementsVisible = false;
    }, 5000);
  }
}
