import {RouterModule, Routes} from "@angular/router";
import {AngularFireAuthGuard} from "@angular/fire/auth-guard";
import {SlideshowComponent} from "./slideshow/slideshow.component";
import {NgModule} from "@angular/core";
import {PageNotFoundComponent} from "./pageNotFound/page-not-found.component";
import {HomeComponent} from "./home/home.component";
import {ImprintComponent} from "./imprint/imprint.component";
import {DataPrivacyComponent} from "./dataPrivacy/dataprivacy.component";

export const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'imprint', component: ImprintComponent},
  {path: 'dataprivacy', component: DataPrivacyComponent},
  {path: 'slideshow', component: SlideshowComponent, canActivate: [AngularFireAuthGuard]},
  {path: '404', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
    )
  ],
  exports: [RouterModule],
  providers: [AngularFireAuthGuard]
})
export class AppRoutingModule {
}
