import { Component } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import {ResetPasswordComponent} from "../resetPassword/resetpassword.component";
import {Alert} from "../model/alert.model";

@Component({
	templateUrl: './login.component.html'
})
export class LoginComponent {
	email: string;
	password: string;

  alert: Alert;

	constructor(public activeModal: NgbActiveModal, private afAuth: AngularFireAuth, private modalService: NgbModal) {}

	login(): void {
		this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password).then(firebaseUser => {
			this.activeModal.close();
		}).catch(error => {
      this.alert = { message: error, type: "danger"}
		});
	}

	resetPassword(): void {
    this.modalService.open(ResetPasswordComponent).result.then(data => {
    });
  }

  close(alert: Alert): void {
    this.alert = null;
  }
}
