import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
  faArrowLeft = faArrowLeft;

  constructor(private route: ActivatedRoute) {}
}
