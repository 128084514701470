import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireAuth} from '@angular/fire/auth';
import UserCredential = firebase.auth.UserCredential;
import {Alert} from "../model/alert.model";

@Component({
  templateUrl: './register.component.html'
})
export class RegisterComponent {
  email: string;
  password: string;
  confirmPassword: string;

  alert: Alert;

  constructor(public activeModal: NgbActiveModal, private afAuth: AngularFireAuth) {
  }

  register(): void {

    if (this.password.length < 8) {
      this.alert = { message: 'The password is too short.', type: "danger"};
      return;
    }

    if (this.password.length > 20) {
      this.alert = { message: 'The password is too long.', type: "danger"};
      return;
    }

    if (!/\d/.test(this.password)) {
      this.alert = { message: 'Please use numbers in your password.', type: "danger"};
      return;
    }

    if (!/[a-zA-ZäöüÄÖÜß]/.test(this.password)) {
      this.alert = { message: 'Please use letters in your password.', type: "danger"};
      return;
    }

    if (this.password != this.confirmPassword) {
      this.alert = { message: 'The passwords are not equal.', type: "danger"};
      return;
    }

    this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password).then((firebaseUser: UserCredential) => {
      this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
        this.activeModal.close();
      });
    }).catch(error => {
      this.alert = { message: error, type: "danger"};
    });
  }

  close(alert: Alert): void {
    this.alert = null;
  }

  login() {
    this.activeModal.close("login");
  }
}
