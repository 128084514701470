import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import {AngularFireStorage} from "@angular/fire/storage";
import {AngularFirestore, DocumentChangeAction} from "@angular/fire/firestore";
import {Alert} from "../model/alert.model";
import {first} from "rxjs/operators";
import {Photo} from "../model/photo.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
	templateUrl: './deleteuser.component.html'
})
export class DeleteUserComponent {

  clicked: boolean = false;

  alert: Alert;

	constructor(public activeModal: NgbActiveModal, private afAuth: AngularFireAuth, private storage: AngularFireStorage, private firestore: AngularFirestore, private translate: TranslateService) {}

	delete(): void {
    this.clicked = true;

    const uid = this.afAuth.auth.currentUser.uid;

    this.firestore.collection('photos', ref => ref.where('user', '==', uid)).snapshotChanges().pipe(first()).subscribe((result: DocumentChangeAction<Photo>[]) => {
      let promises = [];
      result.forEach((photoDocument: DocumentChangeAction<Photo>) => {
        let photoId: string = photoDocument.payload.doc.id;

        promises.push(this.storage.ref(photoId + ".jpg").delete());
        promises.push(photoDocument.payload.doc.ref.delete());
      });

      Promise.all(promises).then(() => {
        return this.afAuth.auth.currentUser.delete();
      }).then(() => {
        this.translate.get('deleteuser.success').pipe(first()).subscribe((result: string) => {
          this.alert = { message: result, type: "success"}
        });
      }).catch(error => {
        this.clicked = false;
        this.alert = { message: error, type: "danger"}
      });
    })
	}

  close(alert: Alert): void {
    this.alert = null;
  }
}
