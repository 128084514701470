import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import {Alert} from "../model/alert.model";

@Component({
	templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent {
	email: string;

	clicked: boolean = false;

  alert: Alert;

	constructor(public activeModal: NgbActiveModal, private afAuth: AngularFireAuth) {}

	resetPassword() {
	  this.clicked = true;

	  this.afAuth.auth.sendPasswordResetEmail(this.email).then(() => {
      this.alert = { message: "Email has been send", type: "success"}
    }).catch(error => {
      this.alert = { message: error, type: "danger"}
      this.clicked = false;
    });
  }

  close(alert: Alert): void {
    this.alert = null;
  }
}
