// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyAu-rROuh11cM5qVrxfNaNhnbl8HV-RaUc",
		authDomain: "photobox-2a97f.firebaseapp.com",
		databaseURL: "https://photobox-2a97f.firebaseio.com",
		projectId: "photobox-2a97f",
		storageBucket: "photobox-2a97f.appspot.com",
		messagingSenderId: "1021842238603",
		appId: "1:1021842238603:web:bab7ca6545ec3177d3b88e",
		measurementId: "G-50J6B8TJEN"
	}
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
