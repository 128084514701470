import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  templateUrl: './dataPrivacy.component.html'
})
export class DataPrivacyComponent {

  faArrowLeft = faArrowLeft;

  constructor(private firestore: AngularFirestore, private afAuth: AngularFireAuth) { }
}
