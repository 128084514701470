import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireAuth} from '@angular/fire/auth';
import {Alert} from "../model/alert.model";

@Component({
  templateUrl: './verifyaccount.component.html'
})
export class VerifyAccountComponent {

  alert: Alert;

  constructor(public activeModal: NgbActiveModal, private afAuth: AngularFireAuth) {
  }

  close(alert: Alert): void {
    this.alert = null;
  }

  resendVerification() {
    this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
      this.alert = {message: 'Verification email has been sent!', type: "success"};
    }).catch(error => {
      this.alert = {message: error, type: "danger"};
    });
  }
}
