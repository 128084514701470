import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {SlideshowComponent} from "./slideshow/slideshow.component";
import {RegisterComponent} from "./register/register.component";
import {ResetPasswordComponent} from "./resetPassword/resetpassword.component";
import {DeleteUserComponent} from "./deleteUser/deleteuser.component";
import {AppRoutingModule} from "./app-routing.module";
import {PageNotFoundComponent} from "./pageNotFound/page-not-found.component";
import {HomeComponent} from "./home/home.component";
import {FooterComponent} from "./footer/footer.component";
import {ImprintComponent} from "./imprint/imprint.component";
import {DataPrivacyComponent} from "./dataPrivacy/dataprivacy.component";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {VerifyAccountComponent} from "./verifyAccount/verifyaccount.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
    RegisterComponent,
    SlideshowComponent,
    ResetPasswordComponent,
    DeleteUserComponent,
    PageNotFoundComponent,
    HomeComponent,
    FooterComponent,
    ImprintComponent,
    DataPrivacyComponent,
    VerifyAccountComponent
	],
	imports: [
		BrowserModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFireAuthModule,
		NgbModule,
		FormsModule,
		LazyLoadImageModule,
		FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
	],
	providers: [],
	bootstrap: [AppComponent],
	entryComponents: [LoginComponent, RegisterComponent, ResetPasswordComponent, DeleteUserComponent, VerifyAccountComponent]
})
export class AppModule { }
